import { ref, watch, computed } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useUserList() {
  const toast = useToast()

  const refUserListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'gender', sortable: true },
    { key: 'type_user', label: 'Type', sortable: true },
    { key: 'status_verification', label: 'Status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUser = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const typeFilter = ref(null)
  const genderFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUser.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, searchQuery, statusFilter, genderFilter, typeFilter],
    () => {
      refetchData()
    },
  )

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('master-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        typeuser: typeFilter.value,
        genderuser: genderFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { userdatas, total_data: total } = response.data.data

        callback(userdatas)
        totalUser.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching User' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // Export users
  const exportData = ref([])
  const refButtonExport = ref(null)

  const exportUsers = callback => {
    store
      .dispatch('master-user/exportUsers')
      .then(response => {
        const { data } = response.data
        callback(data)
      })
      .catch(() => {})
  }

  const handleExport = async () => {
    if (!exportData.value.length) {
      try {
        // Call the exportUsers function to fetch the data
        const fetchedData = await new Promise((resolve, reject) => {
          exportUsers(data => resolve(data))
        })

        // Wait for the data to be fetched
        exportData.value = fetchedData

        toast({
          component: ToastificationContent,
          props: {
            title: 'Success Load Export from database',
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Load Export from database',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    } else {
      refButtonExport.value.$el.click()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success Export CSV',
          icon: 'BellIcon',
        },
      })
    }
  }

  const resolveUserStatusVariant = status => {
    if (status === 'approve') return 'success'
    if (status === 'request') return 'info'
    return 'warning' // for unrequested status
  }

  const resolveUserStatusName = value => {
    if (value === 'approve') return 'Approved'
    if (value === 'request') return 'Requested'
    return 'Unrequested'
  }

  const resolveUserGenderIcon = gender => {
    if (gender === 'male') return 'ri-men-line text-info'
    if (gender === 'female') return 'ri-women-line text-danger'
    return 'ri-genderless-line text-secondary'
  }

  const resolveUserTypeVariant = type => {
    if (type === 'free') return 'info'
    return 'warning'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUser,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    statusFilter,
    typeFilter,
    genderFilter,
    resolveUserStatusVariant,
    resolveUserTypeVariant,
    resolveUserStatusName,
    resolveUserGenderIcon,

    refetchData,

    // Export users
    refButtonExport,
    exportData,
    handleExport,
  }
}
