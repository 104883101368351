<template>
  <div>
    <b-card no-body>
      <h4 class="m-2">
        User Data
      </h4>
    </b-card>

    <!-- Filters -->
    <users-list-filters
      :type-filter.sync="typeFilter"
      :gender-filter.sync="genderFilter"
      :status-filter.sync="statusFilter"
      :type-options="typeOptions"
      :gender-options="genderOptions"
      :status-options="statusOptions"
    />

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="mb-2 mr-0">
          <b-col class="d-flex align-items-center justify-content-end">
            <vue-json-to-csv
              ref="refButtonExport"
              class="invisible"
              :json-data="exportData"
              :csv-title="'Users'"
            />

            <b-button
              :variant="exportData.length === 0 ? 'outline-primary' : 'primary'"
              @click="handleExport"
            >
              <feather-icon
                :icon="
                  exportData.length === 0 ? 'DownloadCloudIcon' : 'DownloadIcon'
                "
                size="18"
              />
              <span class="align-middle ml-50">{{
                exportData.length === 0
                  ? $t('common.load') + ' ' + $t('common.export')
                  : $t('common.export') + ' CSV'
              }}</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <user-list-approve
            :is-approve-user-modal-show.sync="isApproveUserModalShow"
            :data.sync="selectedUser"
            @refetch-data="refetchData"
          />

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <label>{{ $tc('common.entries', perPage) }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-right justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search') + '...'"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        :items="fetchUsers"
        responsive
        :fields="translatedTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('message.empty')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-sort-reset
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </template>

        <!-- Column: Gender -->
        <template #cell(name)="data">
          <span class="text-capitalize">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Gender -->
        <template #cell(gender)="data">
          <div class="text-nowrap d-flex align-items-center">
            <i
              :class="resolveUserGenderIcon(data.value)"
              style="font-size: 18px; margin-right: 0.5rem;"
            />
            <span class="align-text-top text-capitalize">
              {{ data.value }}
            </span>
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(type_user)="data">
          <span
            v-if="data.value === 'free'"
            class="badge badge-primary text-capitalize"
          >
            {{ data.value }}
          </span>
          <span v-else class="badge badge-warning text-capitalize">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status_verification)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.value)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusName(data.value) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`user-row-${data.item._id}-eye-icon`"
              icon="EyeIcon"
              class="cursor-pointer mx-1"
              size="16"
              @click="openEditUserModal(data.item)"
            />

            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'master-user-view',
                  params: { id: data.item._id },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{
                  $t('common.details')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $tc('common.showing', dataMeta.of, {
                first: dataMeta.from,
                last: dataMeta.to,
                total: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUser"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useUsersList from './useUserList'
import UserListApprove from './UsersListApprove.vue'
import UsersListFilters from './UsersListFilters.vue'
import userStoreModule from '../userStoreModule'
import 'animate.css'

export default {
  components: {
    UserListApprove,
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`user.tableHeader.${column.key}`),
      }))
    },
  },

  setup() {
    const USER_MASTER_STORE_MODULE_NAME = 'master-user'

    // Register module
    if (!store.hasModule(USER_MASTER_STORE_MODULE_NAME))
      store.registerModule(USER_MASTER_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_MASTER_STORE_MODULE_NAME))
        store.unregisterModule(USER_MASTER_STORE_MODULE_NAME)
    })

    const typeOptions = [
      { label: 'Free', value: 'free' },
      { label: 'VIP', value: 'vip' },
    ]

    const genderOptions = [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
    ]

    const statusOptions = [
      { label: 'Request', value: 'request' },
      { label: 'Unrequested', value: 'unrequested' },
      { label: 'Approved', value: 'approve' },
    ]

    const isAddNewUserModalShow = ref(false)
    const isApproveUserModalShow = ref(false)
    const selectedUser = ref(null)

    const openEditUserModal = data => {
      selectedUser.value = data
      isApproveUserModalShow.value = true
    }

    const {
      fetchUsers,
      refetchData,
      deleteUser,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refUserListTable,
      resolveUserStatusVariant,
      resolveUserStatusName,
      resolveUserGenderIcon,
      currentPage,
      totalUser,
      dataMeta,
      typeFilter,
      genderFilter,

      // Export users
      refButtonExport,
      exportData,
      handleExport,
    } = useUsersList()

    return {
      fetchUsers,
      refetchData,
      deleteUser,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      typeFilter,
      genderFilter,
      isSortDirDesc,
      tableColumns,
      refUserListTable,
      totalUser,
      currentPage,
      dataMeta,
      resolveUserStatusVariant,
      resolveUserStatusName,
      resolveUserGenderIcon,

      openEditUserModal,
      isAddNewUserModalShow,
      isApproveUserModalShow,
      selectedUser,
      statusOptions,
      genderOptions,
      typeOptions,

      // Export users
      refButtonExport,
      exportData,
      handleExport,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
