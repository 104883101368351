<template>
  <!-- Add new user modal -->
  <b-modal
    id="modal-view-user"
    title="User Selfie Detail"
    centered
    ok-variant="success"
    cancel-variant="danger"
    ok-title="Approve"
    cancel-title="Reject"
    :visible="isApproveUserModalShow"
    :hide-footer="!$can('update', 'User Verification')"
    @ok="handle"
    @cancel="handle"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group :state="nameState" invalid-feedback="User name is required">
        <b-form-input
          id="user"
          v-model="userName"
          type="text"
          :state="nameState"
          placeholder="User Name"
          disabled
        />
      </b-form-group>
    </form>
    <!-- <b-row>
      <b-col
        v-for="(image, key) in image"
        :key="key"
      >
        <b-img-lazy
          thumbnail
          :src="image.thumbnail ? image.thumbnail : image.path"
          height="100"
          width="100"
        />
      </b-col>
    </b-row> -->
    <div class="px-1">
      <vue-picture-swipe
        ref="refPictureSwipe"
        :items="imageItems"
        :options="options"
      />
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="danger"
        :disabled="
          isApprovedOrUnrequested || isLoading || !$can('update', 'Users')
        "
        @click="cancel()"
      >
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          Reject
        </span>
      </b-button>
      <b-button
        variant="success"
        :disabled="
          isApprovedOrUnrequested || isLoading || !$can('update', 'Users')
        "
        @click="ok()"
      >
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          Approve
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BButton,
  BSpinner,
  VBModal,
} from 'bootstrap-vue'
import { ref, watch, computed, nextTick } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePictureSwipe from 'vue-picture-swipe'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BButton,
    BSpinner,
    VuePictureSwipe,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditUserModalShow', 'data'],
    event: ['update:is-edit-user-modal-show', 'update:data'],
  },
  props: {
    isApproveUserModalShow: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const userName = ref('')
    const image = ref([])
    const nameState = ref(null)
    const status = ref(null)
    const refPictureSwipe = ref(null)
    const options = ref({
      shareEl: false,
      bgOpacity: 0.6,
    })
    const isLoading = ref(false)
    const toast = useToast()

    // create computed property that return false or true
    const isApprovedOrUnrequested = computed(() => {
      if (props.data?.status_verification === 'request') {
        return false
      }
      return true
    })

    const imageItems = computed(() => {
      // map into new array object with src and thumbnail key from image data if image not an empty array
      if (image.value.length > 0) {
        return image.value.map(item => ({
          src: item.path,
          thumbnail: item.thumbnail,
          w: 720,
          h: 1280,
        }))
      }
      return []
    })

    watch(
      () => props.isApproveUserModalShow,
      val => {
        if (val) {
          userName.value = props.data.email
          // eslint-disable-next-line no-underscore-dangle
          store
            .dispatch('master-user/detailImage', props.data._id)
            .then(res => {
              if (res.data.code === 200) {
                // eslint-disable-next-line no-param-reassign
                image.value = res.data.data
              }
            })

          nextTick(() => {
            const el = refPictureSwipe.value.$el
            el.firstChild.classList.add('row')
          })
        }
      },
    )

    const handleSubmit = val => {
      if (val === 'cancel') {
        status.value = 'reject'
      } else {
        status.value = 'approve'
      }
      isLoading.value = true
      store
        .dispatch('master-user/updateVerificationState', {
          status: status.value,
          id: props.data._id,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-approve-user-modal-show', false)
          store.commit('socket/DECREASE_VERIFICATION_REQUEST')
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title:
                status.value === 'approve'
                  ? 'Berhasil approve pengguna'
                  : 'Berhasil reject pengguna',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error mengubah pengguna',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handle = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      handleSubmit(bvModalEvt.trigger)
    }

    const resetModal = () => {
      userName.value = ''
      nameState.value = null
      image.value = []
      // eslint-disable-next-line no-param-reassign
      emit('update:is-approve-user-modal-show', false)
      emit('update:data', null)
    }

    return {
      userName,
      image,
      handleSubmit,
      isApprovedOrUnrequested,
      nameState,
      handle,
      refPictureSwipe,
      imageItems,
      resetModal,
      options,
      isLoading,
    }
  },
}
</script>

<style>
img[itemprop='thumbnail'] {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
}

.gallery-thumbnail {
  position: relative;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

[dir='ltr'] .gallery-thumbnail {
  padding-right: 1rem;
  padding-left: 1rem;
}
</style>
