import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/datauser', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/datauser/detail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailImage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/request/verfication/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVerificationState(ctx, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/request/verfication/update/status/${id}`, { status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/datauser?is_export=true`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // User Validation
    fetchUserValidations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/data-validation', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserField(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/data-validation/fields/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserValidation(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/data-validation/update/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
